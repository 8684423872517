<template>
  <div class="full login">
    <div class="logo"></div>
    <div class="pane">
      <!-- 线上 -->
      <!-- <template v-if="VUE_APP_CONFIG === 'online'"> -->
        <input type="text" placeholder="请输入账号" v-model="account" ref="account" />
        <div class="pass-input">
          <input
            :type="[canlook ? 'text' : 'password']"
            placeholder="请输入用密码"
            v-model="pass"
            ref="pass"
            autocomplete
          />
          <div class="eye" :class="{ 'look-icon': canlook, 'noLook-icon': !canlook }" @click="canlook = !canlook"></div>
        </div>
      <!-- </template> -->
      <!-- 排序查询网址登录 -->
      <!-- <template v-if="VUE_APP_CONFIG === 'qcc'">
        <input type="text" placeholder="请输入手机号码" v-model="phone" ref="phone" />
        <div class="pass-box">
            <input type="text" placeholder="请输入验证码" v-model="sms" ref="sms" />
            <SmsBtn
              :phone="phone"
              :disabled="!phone"
            ></SmsBtn>
        </div>

      </template> -->
      <button type="button" @click="login">登录</button>
    </div>
  </div>
</template>
<script>
import SmsBtn from '@/components/mobile/smsBtn/index.vue'
export default {
  data() {
    return {
      account: '',
      pass: '',
      canlook: false,
      // 验证码登录
      phone:'',
      sms:''
    };
  },
  components:{
    SmsBtn
  },
  methods: {
    login() {
     let redirect = this.$route.query.redirect || '/';
      let loginInfo = { account: this.account, password: this.pass, type: 'ap' };
      this.$store.dispatch('login', loginInfo).then(res => {
        if (res.success) {
          this.$store.commit('set', { type: 'isLogin', data: res.success });
          this.$router.push(redirect);
        } else {
          this.$message({
            type: 'error',
            message: '账户或密码错误',
          });
          this.$refs.account.focus();
        }
      });
    }
  },
};
</script>

<style lang="less">
.logo {
  width: 155px;
  height: 50px;
  background-image: url('~@/assets/image/icon-progress/logo.png');
  margin: 40vh auto 0 auto;
  transform: translateY(-20vh);
  -moz-transform: translateY(-20vh);
}
.pane {
  text-align: center;
  input {
    margin: 0;
    padding-left: 1rem;
    width: 24rem;
    height: 3.58rem;
    border-radius: 0.45rem;
    margin-bottom: 2rem;
  }
  button {
    color: #fff;
    width: 24rem;
    height: 3.58rem;
    border-radius: 0.45rem;
    border: unset;
    background-color: #13bfff;
    box-shadow: 0 0 5px #13bffa;
  }
}

.pass-input {
  position: relative;
  display: inline-block;
  .eye {
    position: absolute;
    right: 1.07rem;
    top: 1.07rem;
    width: 20px;
    height: 16px;
    background-size: cover;

    &:hover {
      cursor: pointer;
    }
  }

  .look-icon {
    background-image: url('~@/assets/image/sidebar/look.png');
  }

  .noLook-icon {
    background-image: url('~@/assets/image/sidebar/nolook.png');
  }
}

.pass-box{
  width: 24rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
  .sms-normal{
    width: 96px;
    border: 0;
    background: #fff;
    color: #13BFFF;
    box-shadow: none;
    border-left: 1px solid #EAEDF3;
    height: 3.58rem;
    line-height: 3.58rem;
  }
}
</style>
