<template>
    <div
        slot="button"
        size="small"
        :class="['sms-normal', disabled ? 'is-disabled' : '']"
        @click="handleSendSms"
    >
        <span>{{ smsTxt }}</span>
    </div>


</template>

<script>
import { phoneReg } from '@/utils/utils'
import { postSms } from '@/service/help.js';
export default {
    name:'smsBtns',
    props:{
        phone: String,
        disabled: Boolean,
    },
    data(){
        return{
            smsTxt: '获取验证码',
            isSend: true
        }
    },
    created(){

    },
    methods:{
        handleCountDown(){
            // 重新获取验证码的倒计时
            let wait = 60
            this.isSend = false
            this.smsTxt = '(' + wait + 's)'
            var times = setInterval(_ => {
                wait--
                if (wait == 0) {
                    clearInterval(times)

                    this.smsTxt = '获取验证码'
                    this.isSend = true
                } else {
                    this.smsTxt = '(' + wait + 's)'
                }
            }, 1000)
        },
        handleSendSms(){
            if(this.disabled) return
            if (!phoneReg.test(this.phone)) {
                throwErrorTips('', '手机号码格式错误')
                return
            }
            if(this.isSend){
                let opt = 'login'; // modpassword  login
                postSms({ opt: opt, phone: this.phone }).then(res => {
                    const { success, msg } = res.data
                    if (!success) {
                        throwErrorTips(``, `获取验证码失败！${msg}`)
                    } else {
                        throwSuccessTips('验证码已发送至手机')
                        this.handleCountDown()
                    }
                })
            }
        }
    }


}
</script>

<style lang="less" scoped>
.is-disabled{
    cursor: not-allowed;
    color: #999 !important;
}
</style>